// @ts-ignore
import store from '@/state/store'

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
    },
    // @ts-ignore
    component: () => import('./views/home'),
  },
  {
    path: '/login',
    name: 'login',
    // @ts-ignore
    component: () => import('./views/account/login'),
    meta: {
      // @ts-ignore
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/register',
    name: 'Register',
    // @ts-ignore
    component: () => import('./views/account/register'),
    meta: {
      // @ts-ignore
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    // @ts-ignore
    component: () => import('./views/account/forgot-password'),
    meta: {
      // @ts-ignore
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      // @ts-ignore
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/404',
    name: '404',
    // @ts-ignore
    component: require('./views/utility/404').default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/area',
    name: 'Area',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/area/area')
  },
  {
    path: '/soal',
    name: 'Soal',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/soal')
  },
  {
    path: '/soalBacaQuran',
    name: 'soalBacaQuran',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/bacaQuran/soalList')
  },

  {
    path: '/soalBacaQuran',
    name: 'soalBacaQuran',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/bacaQuran/soalList')
  },
  {
    path: '/soalPsikotes',
    name: 'soalPsikotes',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/Psikotes/soalList')
  },
  {
    path: '/soalPraktekIbadah',
    name: 'soalPraktekIbadah',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/PraktekIbadah/soalList')
  },
  {
    path: '/soalHafalanJuz',
    name: 'soalHafalanJuz',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/HafalanJuz/soalList')
  },
  {
    path: '/soalBerhitungSoal',
    name: 'soalBerhitungSoal',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/BerhitungSoal/soalList')
  },
  {
    path: '/soalBerhitungAngka',
    name: 'soalBerhitungAngka',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/BerhitungAngka/soalList')
  },
  {
    path: '/soalBahasaIndonesia',
    name: 'soalBahasaIndonesia',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/BahasaIndonesia/soalList')
  },
  {
    path: '/soalHukumTajwids',
    name: 'soalHukumTajwids',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/HukumTajwids/soalList')
  },
  {
    path: '/soalImla',
    name: 'soalImla',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/Imla/soalList')
  },
  {
    path: '/jenjang',
    name: 'Jenjang',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/jenjang/jenjang')
  },
  {
    path: '/matpel',
    name: 'Matpel',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/matpel/matpel')
  },
  {
    path: '/guru',
    name: 'Guru',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/guru/container')
  },
  {
    path: '/murid',
    name: 'Murid',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/murid/container')
  },
  {
    path: '/paket',
    name: 'Paket',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/pakets/tryout')
  },
  {
    path: '/koreksi',
    name: 'Koreksi',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/koreksi/koreksi')
  },
  {
    path: '/addSoal',
    name: 'addSoal',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/addsoal')
  },
  {
    path: '/paketSoal',
    name: 'paketSoal',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/paketSoal')
  },
  {
    path: '/addSoalBacaQuran',
    name: 'addSoalBacaQuran',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/bacaQuran/addSoalBacaQuran')
  },
  {
    path: '/addSoalPsikotes',
    name: 'addSoalPsikotes',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/Psikotes/addSoalPsikotes')
  },
  {
    path: '/addSoalAmaliyah',
    name: 'addSoalAmaliyah',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/PraktekIbadah/addSoalAmaliyah')
  },
  {
    path: '/addSoalQoliyah',
    name: 'addSoalQoliyah',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/PraktekIbadah/addSoalQoliyah')
  },
  {
    path: '/addHafalanJuz',
    name: 'addHafalanJuz',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/HafalanJuz/addSoalHafalanJuz')
  },
  {
    path: '/addBerhitungSoal',
    name: 'addBerhitungSoal',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/BerhitungSoal/addSoalBerhitungSoal')
  },
  {
    path: '/addBerhitungAngka',
    name: 'addBerhitungAngka',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/BerhitungAngka/addSoalBerhitungAngka')
  },
  {
    path: '/addBahasaIndonesia',
    name: 'addBahasaIndonesia',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/BahasaIndonesia/addSoalIndonesia')
  },
  {
    path: '/addHukumTajwids',
    name: 'addHukumTajwids',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/HukumTajwids/addSoalHukumTajwids')
  },
  {
    path: '/addImla',
    name: 'addImla',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/Imla/addSoalImla')
  },
  {
    path: '/editSoal',
    name: 'editSoal',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/soal/editSoal')
  },
  {
    path: '/calendar',
    name: 'Calendar',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/calendar/calendar')
  },
  {
    path: '/ecommerce/product-detail',
    name: 'Product Detail',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ecommerce/product-detail')
  },
  {
    path: '/ecommerce/orders',
    name: 'Orders',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ecommerce/orders')
  },
  {
    path: '/ecommerce/customers',
    name: 'Customers',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ecommerce/customers')
  },
  {
    path: '/ecommerce/cart',
    name: 'Cart',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ecommerce/cart')
  },
  {
    path: '/ecommerce/checkout',
    name: 'Checkout',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ecommerce/checkout')
  },
  {
    path: '/ecommerce/shops',
    name: 'Shops',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ecommerce/shops')
  },
  {
    path: '/ecommerce/add-product',
    name: 'Add Product',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ecommerce/add-product')
  },
  {
    path: '/invoices/detail',
    name: 'Invoice Detail',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/invoices/detail')
  },
  {
    path: '/invoices/list',
    name: 'Invoice List',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/invoices/list')
  },
  {
    path: '/ui/alerts',
    name: 'Alerts',
    meta: {
      authRequired: true
    },
    // @ts-ignore
    component: () => import('./views/ui/alerts')
  },
  {
    path: '/ui/buttons',
    name: 'Buttons',
    meta: {
      authRequired: true
    },
    // @ts-ignore
    component: () => import('./views/ui/buttons')
  },
  {
    path: '/ui/cards',
    name: 'Cards',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/cards')
  },
  {
    path: '/ui/carousel',
    name: 'Carousel',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/carousel')
  },
  {
    path: '/ui/dropdowns',
    name: 'Dropdowns',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/dropdowns')
  },
  {
    path: '/ui/grid',
    name: 'Grid',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/grid')
  },
  {
    path: '/ui/images',
    name: 'Images',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/images')
  },
  {
    path: '/ui/modals',
    name: 'Modals',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/modals')
  },
  {
    path: '/ui/rangeslider',
    name: 'Rangeslider',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/rangeslider')
  },
  {
    path: '/ui/progressbars',
    name: 'Progressbars',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/progressbars')
  },
  {
    path: '/ui/sweet-alert',
    name: 'Sweet-alert',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/sweet-alert')
  },
  {
    path: '/ui/tabs-accordions',
    name: 'Tabs-accordions',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/tabs-accordions')
  },
  {
    path: '/ui/typography',
    name: 'Typography',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/typography')
  },
  {
    path: '/ui/video',
    name: 'Video',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/video')
  },
  {
    path: '/ui/general',
    name: 'General',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/general')
  },
  {
    path: '/ui/colors',
    name: 'Colors',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/ui/colors')
  },
  {
    path: '/projects/grid',
    name: 'Projects Grid',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/projects/projects-grid')
  },
  {
    path: '/projects/list',
    name: 'Projects List',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/projects/projects-list')
  },
  {
    path: '/projects/overview',
    name: 'Project Overview',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/projects/overview')
  },
  {
    path: '/projects/create',
    name: 'Create New',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/projects/create')
  },
  {
    path: '/contacts/grid',
    name: 'User Grid',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/contacts/contacts-grid')
  },
  {
    path: '/contacts/list',
    name: 'User List',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/contacts/contacts-list')
  },
  {
    path: '/contacts/profile',
    name: 'Profile',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/contacts/contacts-profile')
  },
  {
    path: '/charts/apex',
    name: 'Apex chart',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/charts/apex')
  },
  {
    path: '/charts/chartjs',
    name: 'Chartjs chart',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/charts/chartjs/index')
  },
  {
    path: '/charts/chartist',
    name: 'Chartist chart',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/charts/chartist')
  },
  {
    path: '/icons/boxicons',
    name: 'Boxicons Icon',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/icons/boxicons')
  },
  {
    path: '/icons/materialdesign',
    name: 'Materialdesign Icon',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/icons/materialdesign')
  },
  {
    path: '/icons/dripicons',
    name: 'Dripicons Icon',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/icons/dripicons')
  },
  {
    path: '/icons/fontawesome',
    name: 'Fontawesome Icon',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/icons/fontawesome')
  },
  {
    path: '/maps/google',
    name: 'Google Maps',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/maps/google')
  },
  {
    path: '/tables/basictable',
    name: 'Basic Tables',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/tables/basictable')
  },
  {
    path: '/tables/advancedtable',
    name: 'Advanced Tables',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/tables/advancedtable')
  },
  {
    path: '/form/advanced',
    name: 'Form Advanced',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/forms/advanced')
  },
  {
    path: '/form/elements',
    name: 'Form Elements',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/forms/elements')
  },
  {
    path: '/form/editor',
    name: 'CK Editor',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/forms/ckeditor')
  },
  {
    path: '/form/uploads',
    name: 'File Uploads',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/forms/uploads')
  },
  {
    path: '/form/validation',
    name: 'Form Validation',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/forms/validation')
  },
  {
    path: '/form/wizard',
    name: 'Form Wizard',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/forms/wizard')
  },
  {
    path: '/form/repeater',
    name: 'Form Repeater',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/forms/repeater')
  },
  {
    path: '/form/mask',
    name: 'Form Mask',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/forms/mask')
  },
  {
    path: '/pages/starter',
    name: 'Starter',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/utility/starter')
  },
  {
    path: '/pages/maintenance',
    name: 'Maintenance',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/utility/maintenance')
  },
  {
    path: '/pages/timeline',
    name: 'Timeline',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/utility/timeline')
  },
  {
    path: '/pages/faqs',
    name: 'FAQs',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/utility/faqs')
  },
  {
    path: '/pages/pricing',
    name: 'Pricing',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/utility/pricing')
  },
  {
    path: '/pages/404',
    name: 'Error-404',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/utility/404')
  },
  {
    path: '/pages/500',
    name: 'Error-500',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/utility/500')
  },
  {
    path: '/email/inbox',
    name: 'Inbox',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/email/inbox')
  },
  {
    path: '/email/reademail',
    name: 'Read Email',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/email/reademail')
  },
  {
    path: '/tasks/list',
    name: 'Task list',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/tasks/task-list')
  },
  {
    path: '/tasks/kanban',
    name: 'Kanbanboard',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/tasks/kanbanboard')
  },
  {
    path: '/tasks/create',
    name: 'Create Task',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/tasks/task-create')
  },
  {
    path: '/layout/horizontal',
    name: 'Horizontal',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/layout/horizontal')
  },
  {
    path: '/auth/login-1',
    name: 'Login sample',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/sample-pages/login-sample')
  },
  {
    path: '/auth/register-1',
    name: 'Register sample',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/sample-pages/register-sample')
  },
  {
    path: '/auth/recoverpw',
    name: 'Recover pwd',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/sample-pages/recoverpw-sample')
  },
  {
    path: '/auth/lock-screen',
    name: 'Lock screen',
    meta: { authRequired: true },
    // @ts-ignore
    component: () => import('./views/sample-pages/lockscreen')
  }
]
