/* eslint-disable no-unused-vars */
export const state = {
    user: null,
    type : "",
}

export const mutations = {
    SET_USER(state, newValue) {
        state.user = newValue;
    },
    SET_TYPE(state, newValue) {
        state.type = newValue;
    }
}

export const getters = {
    // Whether the user is currently logged in.
    user(state) {
        return state.user;
    },
    type(state) {
        return state.type;
    }
}