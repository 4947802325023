class User{
    constructor({id,name,level,address,phone,email,password,sekolah}){
        this._name=name;
        this._id=id;
        this._level=level;
        this._address=address;
        this._phone=phone;
        this._email=email;
        this._password=password;
        this._sekolah=sekolah;
    }

    getId(){
        return this._id;
    }
    getName(){
        return this._name;
    }
    getLevel(){
        return this._level;
    }
    getAddress(){
        return this._address;
    }
    getPhone(){
        return this._phone;
    }
    getEmail(){
        return this._email;
    }
    getPassword(){
        return this._password;
    }
    getSekolah(){
        return this._sekolah;
    }
    setId(id){
        this._id=id;
    }
    setName(name){
        this._name=name;
    }
    setLevel(level){
        this._level=level;
    }
    setAddress(address){
        this._address=address;
    }
    setPhone(phone){
        this._phone=phone;
    }
    setEmail(email){
        this._email=email;
    }
    setPassword(password){
        this._password=password;
    }
    setSekolah(sekolah){
        this._sekolah=sekolah;
    }
}

module.exports = User;