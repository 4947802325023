
const Client = require('./client');
const moment = require('moment');
// const qs = require('qs')
class Koreksi extends Client{
    constructor(){
        super();
        this._endpoint = 'koreksiTryout';
        this._endpointPsikotesSoal = 'psikotes';
        this._endpointBacaQuranSoal = 'bacaQuran';
        this._endpointHukumTajwidsSoal = 'hukumTajwids';
        this._endpointHafalanJuzSoal = 'hafalanJuz';
        this._endpointAmaliyahSoal = 'praktekIbadah/soalAmaliyah';
        this._endpointQoliyahSoal = 'praktekIbadah/soalQoliyah';
        this._endpointImlaSoal = 'imla';
        this._endpointBerhitungAngkaSoal = 'berhitungAngka';
        this._endpointBerhitungSoalSoal = 'berhitungSoal';
        this._endpointBahasaIndonesiaSoal = 'bahasaIndonesia';
        moment.locale('id');
    }
    static states(){
        return {
            error : false,
            loading : true,
            isMatpel : false,
            isCreate : false,
            message : "This value is required.",
            lists : [],
            fields : {
                id : 0,
                nama_murid: {
                    value : "",
                    error : false,
                    message : "This value is required."
                },
                id_paket : {
                    value : {},
                    error : false,
                    message : "This value is required."
                },
                tanggal  : {
                    value : "",
                    error : false,
                    message : "This value is required."
                },
            }
        }
    }
    reset(){
        return {
            error : false,
            loading : false,
            isMatpel : false,
            isCreate : false,
            message : "This value is required.",
            lists : [],
            fields : {
                id : 0,
                nama_murid: {
                    value : "",
                    error : false,
                    message : "This value is required."
                },
                id_paket : {
                    value : {},
                    error : false,
                    message : "This value is required."
                },
                tanggal  : {
                    value : "",
                    error : false,
                    message : "This value is required."
                },
            }
        }
    }
    showModal(states){
        states = this.reset();
        states.isCreate = true
        return states;
    }
    data(){
        return new Promise(function(resolve,reject){
            this.get(this._endpoint)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    list(){
        return new Promise(function(resolve,reject){
            this.get(this._endpoint+"/list")
            .then((result)=>{
                if(result.success){
                    resolve(result.data_tryout)
                }else{
                    reject(result)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    mulaiKoreksi(id_tryout,id_guru){
        return new Promise(function(resolve,reject){
            this.post(this._endpoint+"/mulai?id_tryout="+id_tryout+"&id_guru="+id_guru)
            .then((result)=>{
                if(result.success){
                    resolve(true);
                }else{
                    reject(false);
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    kumpulJawaban(id,status){
        return new Promise(function(resolve,reject){
            this.post(this._endpoint+"/jawaban?id_tryoutDetailSoal="+id+"&status="+status)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    kumpulJawabanPondok(id,status){
        return new Promise(function(resolve,reject){
            this.post(this._endpoint+"/jawabanPondok?id_tryoutDetailSoal="+id+"&status="+status)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    selesaiKoreksi(id){
      return new Promise(function(resolve,reject){
          this.post(this._endpoint+"/matpelSelesai?id_tryoutDetail="+id)
          .then((result)=>{
              resolve(result)
          })
          .catch(err=>reject(err));
      }.bind(this))
    }
    simpanNilaiKoreksi(id, nilai){
      return new Promise(function(resolve,reject){
          this.post(this._endpoint+"/jawabanPondok?id_tryoutDetail="+id+"&nilai="+nilai)
          .then((result)=>{
              resolve(result)
          })
          .catch(err=>reject(err));
      }.bind(this))
    }
    dataMatpel(id){
        return new Promise(function(resolve,reject){
            this.get(this._endpoint+"/matpel?id_tryout="+id)
            .then((result)=>{
                if(result.success){
                    resolve(result.data_tryout)
                }else{
                    reject(result)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    dataMatpelPondok(id){
        return new Promise(function(resolve,reject){
            this.get(this._endpoint+"/pondok?id_tryout="+id)
            .then((result)=>{
                if(result.success){
                    resolve(result.data_tryout)
                }else{
                    reject(result)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    dataKoreksi(id){
        return new Promise(function(resolve,reject){
            this.get(this._endpoint+"/soal?id_tryoutDetail="+id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    getList(states){
        return new Promise(function(resolve,reject){
            this.data()
            .then((result)=>{
                if(result.success){
                    states.error = false
                    states.loading = false
                    states.isMatpel = false
                    states.isCreate = false
                    states.message = ""
                    let lists = result.data_tryout.map((item)=>{
                        return {
                            id : item.id_tryout,
                            nama_murid : item.nama_murid,
                            id_paket : item.paket,
                            nilai : null,
                            nama_matpel : null,
                            tanggal : null,
                            id_tryoutDetailSoals: null,
                            jawaban_user: null,
                            jawaban_benar: null,
                            filename: null,
                            status: item.status,
                            soals: null,
                            pembahasan: null,
                            imgPembahasan: null
                        }
                    })
                    states.lists = lists
                    resolve(states)
                }else{
                    states.error = true
                    states.loading = false
                    states.isMatpel = false
                    states.isCreate = false
                    states.message = result.data_tryout
                    reject(states)
                }
            })
            .catch(err=>{
                states.error = true
                states.loading = false
                states.isMatpel = false
                states.isCreate = false
                states.message = err
                reject(states)
            });
        }.bind(this))
    }
    getMatpel(states, id){
      return new Promise(function(resolve,reject){
          this.dataMatpel(id)
          .then((result)=>{
              if(result.success){
                  states.error = false
                  states.loading = false
                  states.isMatpel = true
                  states.isCreate = false
                  states.message = ""
                  let lists = result.data_tryout.map((item)=>{
                      return {
                        id : null,
                        nama_murid : null,
                        id_paket : null,
                        id_tryoutDetail : item.id_tryoutDetail,
                        nilai : item.nilai,
                        nama_matpel : item.nama_matpel,
                        tanggal : null,
                        id_tryoutDetailSoals: null,
                        jawaban_user: null,
                        jawaban_benar: null,
                        filename: null,
                        status: item.statusKoreksi,
                        soals: null,
                        pembahasan: null,
                        imgPembahasan: null
                      }
                  })
                  states.lists = lists
                  resolve(states)
              }else{
                  states.error = true
                  states.loading = false
                  states.isMatpel = true
                  states.isCreate = false
                  states.message = result.data_tryout
                  reject(states)
              }
          })
          .catch(err=>{
              states.error = true
              states.loading = false
              states.isMatpel = false
              states.isCreate = false
              states.message = err
              reject(states)
          });
      }.bind(this))
    }
    getKoreksi(states, id){
      return new Promise(function(resolve,reject){
          this.dataKoreksi(id)
          .then((result)=>{
              if(result.success){
                  states.error = false
                  states.loading = false
                  states.isMatpel = false
                  states.isCreate = true
                  states.message = ""
                  var nol = 1
                  let lists = result.data_tryout.map((item)=>{
                      return {
                        id : item.id_tryoutDetailSoals,
                        nama_murid : null,
                        id_paket : nol,
                        id_tryoutDetail : item.id_tryoutDetail,
                        nilai : null,
                        nama_matpel : null,
                        tanggal : null,
                        id_tryoutDetailSoals: item.id_tryoutDetailSoals,
                        jawaban_user: item.jawaban_user,
                        jawaban_benar: item.jawaban_benar,
                        filename: item.filename,
                        status: item.status,
                        soals: item.soal,
                        pembahasan: item.pembahasan,
                        imgPembahasan: item.imgPembahasan,
                      }
                  })
                  states.lists = lists
                  resolve(states)
              }else{
                  states.error = true
                  states.loading = false
                  states.isMatpel = false
                  states.isCreate = true
                  states.message = result.data_tryout
                  reject(states)
              }
          })
          .catch(err=>{
              states.error = true
              states.loading = false
              states.isMatpel = false
              states.isCreate = false
              states.message = err
              reject(states)
          });
      }.bind(this))
    }
    getSoalPsikotes(id) {
      return new Promise(function(resolve,reject){
          this.get(this._endpointPsikotesSoal+"/soal?id_tryout="+id)
          .then((result)=>{
              if(result.success){
                  resolve(result.data)
              }else{
                  reject(result)
              }
          })
          .catch(err=>reject(err));
      }.bind(this))
    }
    getSoalBacaQuran(id) {
      return new Promise(function(resolve,reject){
          this.get(this._endpointBacaQuranSoal+"/soal?id_tryout="+id)
          .then((result)=>{
              if(result.success){
                  resolve(result.data)
              }else{
                  reject(result)
              }
          })
          .catch(err=>reject(err));
      }.bind(this))
    }
    getSoalHukumTajwids(id) {
      return new Promise(function(resolve,reject){
          this.get(this._endpointHukumTajwidsSoal+"/soal?id_tryout="+id)
          .then((result)=>{
              if(result.success){
                  resolve(result.data)
              }else{
                  reject(result)
              }
          })
          .catch(err=>reject(err));
      }.bind(this))
    }
    getSoalHafalanJuz(id) {
      return new Promise(function(resolve,reject){
          this.get(this._endpointHafalanJuzSoal+"/soal?id_tryout="+id)
          .then((result)=>{
              if(result.success){
                  resolve(result.data)
              }else{
                  reject(result)
              }
          })
          .catch(err=>reject(err));
      }.bind(this))
    }
    getSoalAmmaliyah(id) {
      return new Promise(function(resolve,reject){
          this.get(this._endpointAmaliyahSoal+"?id_tryout="+id)
          .then((result)=>{
              if(result.success){
                  resolve(result.data)
              }else{
                  reject(result)
              }
          })
          .catch(err=>reject(err));
      }.bind(this))
    }
    getSoalQoliyah(id) {
      return new Promise(function(resolve,reject){
          this.get(this._endpointQoliyahSoal+"?id_tryout="+id)
          .then((result)=>{
              if(result.success){
                  resolve(result.data)
              }else{
                  reject(result)
              }
          })
          .catch(err=>reject(err));
      }.bind(this))
    }
    getSoalImla(id) {
      return new Promise(function(resolve,reject){
          this.get(this._endpointImlaSoal+"/soal?id_tryout="+id)
          .then((result)=>{
              if(result.success){
                  resolve(result.data)
              }else{
                  reject(result)
              }
          })
          .catch(err=>reject(err));
      }.bind(this))
    }
    getSoalBerhitungAngka(id) {
      return new Promise(function(resolve,reject){
          this.get(this._endpointBerhitungAngkaSoal+"/soal?id_tryout="+id)
          .then((result)=>{
              if(result.success){
                  resolve(result.data)
              }else{
                  reject(result)
              }
          })
          .catch(err=>reject(err));
      }.bind(this))
    }
    getSoalBerhitungSoal(id) {
      return new Promise(function(resolve,reject){
          this.get(this._endpointBerhitungSoalSoal+"/soal?id_tryout="+id)
          .then((result)=>{
              if(result.success){
                  resolve(result.data)
              }else{
                  reject(result)
              }
          })
          .catch(err=>reject(err));
      }.bind(this))
    }
    getSoalBahasaIndonesia(id) {
      return new Promise(function(resolve,reject){
          this.get(this._endpointBahasaIndonesiaSoal+"/soal?id_tryout="+id)
          .then((result)=>{
              if(result.success){
                  resolve(result.data)
              }else{
                  reject(result)
              }
          })
          .catch(err=>reject(err));
      }.bind(this))
    }
}


module.exports = Koreksi;
