const Client = require('./client');
const qs = require('qs')
class Murid extends Client{
    constructor() {
        super();
        this._endpoint = 'murid';
    }
    data(filter){
        var url  = this._endpoint;
        var filters = [];
        for (const [key, value] of Object.entries(filter)) {
            filters.push(`${key}=${value}`);
        }
        url += "?"+filters.join('&');
        return new Promise(function(resolve,reject){
            this.get(url)
            .then((result)=>{
                if(result.success){
                    resolve(result.data_murid)
                }else{
                    reject(result);
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    edit(data){
        return new Promise(function(resolve,reject){
            this.put(this._endpoint+"/"+data.id,qs.stringify({
                area: data.name
              }))
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapus(id){
        return new Promise(function(resolve,reject){
            this.delete(this._endpoint+"/"+id)
            .then((result)=>{
                if (result.success) {
                    resolve(result.data)
                } else {
                    reject(result.data)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}

module.exports = Murid;