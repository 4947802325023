/* eslint-disable no-unused-vars */
const User = require('./../../api/users');
const Bayar = require('./../../api/bayar');
const Tryout = require('./../../api/tryout');
const Area = require('./../../api/area');
const Jenjang = require('./../../api/jenjang');
const Users = new User();
const Bayars = new Bayar();
const _tryOut = new Tryout();
const _area = new Area();
const _jenjang = new Jenjang();
var date = new Date();
var tgl = Array.from({ length: new Date(date.getFullYear(), date.getMonth(), 0).getDate() }, (_, i) => 0);
export const state = {
    totalMurid: 0,
    revenue: "0",
    pendingPayment: "0",
    totalTryout: 0,
    tryoutTakenpermonth: tgl,
    areasUser: [],
    areasUserData: [],
    jenjangs: [],
    jenjangSell : [],
}

export const mutations = {
    SET_TOTALMURID(state, value) {
        state.totalMurid = value;
    },
    SET_REVENUE(state, value) {
        state.revenue = value;
    },
    SET_PENDINGPAYMENT(state, value) {
        state.pendingPayment = value;
    },
    SET_TOTALTRYOUT(state, value) {
        state.totalTryout = value;
    },
    SET_TRYOUTMONTH(state, value) {
        state.tryoutTakenpermonth = value;
    },
    SET_AREAUSER(state, value) {
        state.areasUser = value;
    },
    SET_AREAUSERDATA(state, value) {
        state.areasUserData = value;
    },
    SET_JENJANGS(state, value) {
        state.jenjangs = value;
    },
    SET_JENJANGSSELL(state, value) {
        state.jenjangSell = value;
    }
}

export const getters = {
    totalMurid(state) {
        return state.totalMurid;
    },
    revenue(state) {
        return state.revenue;
    },
    pendingPayment(state) {
        return state.pendingPayment;
    },
    totalTryout(state) {
        return state.totalTryout;
    },
    tryoutTakenpermonth(state) {
        return state.tryoutTakenpermonth;
    },
    areasUser(state) {
        return state.areasUser;
    },
    areasUserData(state){
        return state.areasUserData;
    },
    jenjangs(state) {
        return state.jenjangs;
    },
    jenjangSell(state){
        return state.jenjangSell;
    }
}
export const actions = {
    getTotalMurid(context) {
        const { commit, dispatch, getters } = context;
        return new Promise((resolve, reject) => {
            Users.getTotalMurid().then((total) => {
                commit('SET_TOTALMURID', total);
            }).catch((err)=>{commit('SET_TOTALMURID', err);})
        })
    },
    getRevenue(context) {
        const { commit, dispatch, getters } = context;
        return new Promise((resolve, reject) => {
            Bayars.getRevenue(1).then((total) => {
                commit('SET_REVENUE', total);
            }).catch((err)=>{commit('SET_REVENUE', err);})
        })
    },
    getpendingPayment(context) {
        const { commit, dispatch, getters } = context;
        return new Promise((resolve, reject) => {
            Bayars.getRevenue(0).then((total) => {
                commit('SET_PENDINGPAYMENT', total);
            }).catch((err)=>{commit('SET_PENDINGPAYMENT', err);})
        })
    },
    gettotalTryout(context) {
        const { commit, dispatch, getters } = context;
        return new Promise((resolve, reject) => {
            _tryOut.getTryout({"offset":0,"limit":1000}).then((total) => {
                commit('SET_TOTALTRYOUT', total.total);
            }).catch((err)=>{commit('SET_TOTALTRYOUT', err);})
        })
    },
    gettryoutTakenpermonth(context,data) {
        const { commit, dispatch, getters } = context;
        return new Promise((resolve, reject) => {
            _tryOut.getReport({ "type": 1, "filter": data }).then((total) => {
                console.log(total)
                var date = new Date();
                var tgl = Array.from({ length: new Date(date.getFullYear(), date.getMonth(), 0).getDate() }, (_, i) => 0);
                total.forEach((e) => {
                    tgl[e.day - 1] = e.total
                });
                commit('SET_TRYOUTMONTH', tgl);
            }).catch((err) => {
                console.log(err)
                var date = new Date();
                var tgl = Array.from({ length: new Date(date.getFullYear(), date.getMonth(), 0).getDate() }, (_, i) => 0);
                commit('SET_TRYOUTMONTH', tgl);
            })
        })
    },
    // @ts-ignore
    getAreaReport(context) {
        const { commit, dispatch, getters } = context;
        return new Promise((resolve, reject) => {
            _area.report().then((total) => {
                var araes = total.map((e) => e.area);
                var dataareas = total.map((e) => e.murid);
                console.log(araes)
                console.log("SET_AREAUSERDATA")
                commit('SET_AREAUSER', araes);
                commit('SET_AREAUSERDATA', dataareas);
            }).catch((err) => {
                console.log(err)
                commit('SET_AREAUSER', []);
                commit('SET_AREAUSERDATA', []);
            })
        })
    },
    // @ts-ignore
    getJenjangReport(context) {
        const { commit, dispatch, getters } = context;
        return new Promise((resolve, reject) => {
            _jenjang.report().then((total) => {
                var jenjangs = total.map((e) => e.jenjang);
                var jenjangsSell = total.map((e) => e.total);
                commit('SET_JENJANGS', jenjangs);
                commit('SET_JENJANGSSELL', jenjangsSell);
            }).catch((err) => {
                console.log(err)
                commit('SET_JENJANGS', []);
                commit('SET_JENJANGSSELL', []);
            })
        })
    }
}

