/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

const Client = require('./client');
const moment = require('moment');
class Bayar extends Client{
    constructor() {
        super();
        this._endpoint = 'bayar'
    }
    generateUrl(endpoint,filter) {
        var url  = endpoint;
        var filters = [];
        for (const [key, value] of Object.entries(filter)) {
            filters.push(`${key}=${value}`);
        }
        url += "?" + filters.join('&');
        return url
    }
    convertToRupiah(angka)
        {
            var rupiah = '';		
            var angkarev = angka.toString().split('').reverse().join('');
            for(var i = 0; i < angkarev.length; i++) if(i%3 == 0) rupiah += angkarev.substr(i,3)+'.';
            return 'Rp. '+rupiah.split('',rupiah.length-1).reverse().join('');
        }
    getRevenue(status) {
        return new Promise(function (resolve, reject) {
            this.get(this._endpoint+"/revenue?status="+status)
            .then((result)=>{
                if (result.success) {
                    resolve(this.convertToRupiah(result.data.revenue))
                } else {
                    reject(0)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    getReport(filter) {
        return new Promise(function (resolve, reject) {
            this.get(this.generateUrl(this._endpoint,filter))
            .then((result)=>{
                if (result.success) {
                    // resolve(result.data_bayar.data)
                    var tmp = result.data_bayar.data;
                    tmp = tmp.map((e => {
                        return {
                            id: e.id,
                            va_number: e.va_number,
                            metode_pembayaran: e.metode_pembayaran,
                            jumlah: e.jumlah,
                            tgl:moment(e.tgl).format('DD, MMMM - YYYY'),
                            status: e.status,
                            murid:e.murid.name
                        }
                    }))
                    resolve(tmp)
                } else {
                    reject(result)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}

module.exports = Bayar;