
const Client = require('./client');
const qs = require('qs')
class Soal extends Client{
    constructor(){
        // this._client = new Client();
        super();
        this._endpoint = 'soals';
        this._endpointSoalPaket = 'soals/soalPakets';
        this._endpointBacaQuran = 'bacaQuran';
        this._endpointPsikotes = 'psikotes';
        this._endpointPraktekIbadah = 'praktekIbadah';
        this._endpointHafajanJuz = 'hafalanJuz';
        this._endpointBerhitungSoal = 'berhitungSoal';
        this._endpointBerhitungAngka = 'berhitungAngka';
        this._endpointBahasaIndonesia = 'bahasaIndonesia';
        this._endpointHukumTajwids = 'hukumTajwids';
        this._endpointImla = 'imla';
    }
    create(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(this._endpoint,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createBacaQuran(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(this._endpointBacaQuran,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createPsikotes(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(this._endpointPsikotes,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createAmaliyah(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(this._endpointPraktekIbadah+"/amaliyah",data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createQoliyah(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(this._endpointPraktekIbadah+"/qoliyah",data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createHafalanJuz(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(this._endpointHafajanJuz,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createBerhitungAngka(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(this._endpointBerhitungAngka,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createBerhitungSoal(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(this._endpointBerhitungSoal,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createBahasaIndonesia(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(this._endpointBahasaIndonesia,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createHukumTajwids(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(this._endpointHukumTajwids,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createImla(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(this._endpointImla,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    updateSoal(data,id){
        return new Promise(function(resolve,reject){
            this.put(this._endpointSoalPaket+"/"+id,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createVoice(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(this._endpoint+"/voice",data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    data(filter){
        // type {}
        // eslint-disable-next-line no-unused-vars
        var url  = this._endpoint;
        var filters = [];
        for (const [key, value] of Object.entries(filter)) {
            filters.push(`${key}=${value}`);
        }
        url += "?"+filters.join('&');
        return new Promise(function(resolve,reject){
            this.get(url)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    dataSoalPaket(filter){
      var url  = this._endpointSoalPaket;
      var filters = [];
      for (const [key, value] of Object.entries(filter)) {
          filters.push(`${key}=${value}`);
      }
      url += "?"+filters.join('&');
        return new Promise(function(resolve,reject){
            this.get(url)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    dataAlQuran(filter){
        // type {}
        // eslint-disable-next-line no-unused-vars
        var url  = this._endpointBacaQuran;
        var filters = [];
        for (const [key, value] of Object.entries(filter)) {
            filters.push(`${key}=${value}`);
        }
        url += "?"+filters.join('&');
        return new Promise(function(resolve,reject){
            this.get(url)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    dataPsikotes(filter){
        // type {}
        // eslint-disable-next-line no-unused-vars
        var url  = this._endpointPsikotes;
        var filters = [];
        for (const [key, value] of Object.entries(filter)) {
            filters.push(`${key}=${value}`);
        }
        url += "?"+filters.join('&');
        return new Promise(function(resolve,reject){
            this.get(url)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    datapraktekIbadah(filter){
        // type {}
        // eslint-disable-next-line no-unused-vars
        var url  = this._endpointPraktekIbadah;
        var filters = [];
        for (const [key, value] of Object.entries(filter)) {
            filters.push(`${key}=${value}`);
        }
        url += "?"+filters.join('&');
        return new Promise(function(resolve,reject){
            this.get(url)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    dataHafalanJuz(filter){
        // type {}
        // eslint-disable-next-line no-unused-vars
        var url  = this._endpointHafajanJuz;
        var filters = [];
        for (const [key, value] of Object.entries(filter)) {
            filters.push(`${key}=${value}`);
        }
        url += "?"+filters.join('&');
        return new Promise(function(resolve,reject){
            this.get(url)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    dataBerhitungSoal(filter){
        // type {}
        // eslint-disable-next-line no-unused-vars
        var url  = this._endpointBerhitungSoal;
        var filters = [];
        for (const [key, value] of Object.entries(filter)) {
            filters.push(`${key}=${value}`);
        }
        url += "?"+filters.join('&');
        return new Promise(function(resolve,reject){
            this.get(url)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    dataBerhitungAngka(filter){
        // type {}
        // eslint-disable-next-line no-unused-vars
        var url  = this._endpointBerhitungAngka;
        var filters = [];
        for (const [key, value] of Object.entries(filter)) {
            filters.push(`${key}=${value}`);
        }
        url += "?"+filters.join('&');
        return new Promise(function(resolve,reject){
            this.get(url)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    dataBahasaIndonesia(filter){
        // type {}
        // eslint-disable-next-line no-unused-vars
        var url  = this._endpointBahasaIndonesia;
        var filters = [];
        for (const [key, value] of Object.entries(filter)) {
            filters.push(`${key}=${value}`);
        }
        url += "?"+filters.join('&');
        return new Promise(function(resolve,reject){
            this.get(url)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    dataHukumTajwids(filter){
        // type {}
        // eslint-disable-next-line no-unused-vars
        var url  = this._endpointHukumTajwids;
        var filters = [];
        for (const [key, value] of Object.entries(filter)) {
            filters.push(`${key}=${value}`);
        }
        url += "?"+filters.join('&');
        return new Promise(function(resolve,reject){
            this.get(url)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    dataImla(filter){
        // type {}
        // eslint-disable-next-line no-unused-vars
        var url  = this._endpointImla;
        var filters = [];
        for (const [key, value] of Object.entries(filter)) {
            filters.push(`${key}=${value}`);
        }
        url += "?"+filters.join('&');
        return new Promise(function(resolve,reject){
            this.get(url)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    edit(data){
        return new Promise(function(resolve,reject){
            this.put(this._endpoint+"/"+data.id,qs.stringify({
                area: data.name
              }))
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapus(data){
        return new Promise(function(resolve,reject){
            this.delete(this._endpoint+"/"+data.id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapusAlQuran(data){
        return new Promise(function(resolve,reject){
            this.delete(this._endpointBacaQuran+"/"+data.id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapusPsikotes(data){
        return new Promise(function(resolve,reject){
            this.delete(this._endpointPsikotes+"/"+data.id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapusPraktekIbadah(data){
        return new Promise(function(resolve,reject){
            this.delete(this._endpointPraktekIbadah+"/"+data.id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapusHafalanJuz(data){
        return new Promise(function(resolve,reject){
            this.delete(this._endpointHafajanJuz+"/"+data.id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapusBerhitungSoal(data){
        return new Promise(function(resolve,reject){
            this.delete(this._endpointBerhitungSoal+"/"+data.id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapusHukumTajwids(data){
        return new Promise(function(resolve,reject){
            this.delete(this._endpointHukumTajwids+"/"+data.id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapusImla(data){
        return new Promise(function(resolve,reject){
            this.delete(this._endpointImla+"/"+data.id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapusBerhitungAngka(data){
        return new Promise(function(resolve,reject){
            this.delete(this._endpointBerhitungAngka+"/"+data.id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapusBahasaIndonesia(data){
        return new Promise(function(resolve,reject){
            this.delete(this._endpointBahasaIndonesia+"/"+data.id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    validate(fields,matpel,type){
        // eslint-disable-next-line no-unused-vars
        return new Promise(function(res,rej){
            try{
                if(matpel != null && type != null){
                    var haveError = false;
                    var newFields = []
                    var isFiles = false;
                    var isVoices = false;
                    fields.forEach((item)=>{
                        if (item.isVoice == true) {
                          isVoices = true;
                        }else{
                          if(item.soal == ""){
                            item.error = true
                            haveError = true
                            item.errorMessage = "Soal Harus Di isi !"
                          }
                          if(item.isFile == true){
                            isFiles = true;
                          }else{
                            if(item.jawaban == null){
                              haveError = true
                              item.error = true
                              item.errorMessage = "Pilih Jawaban untuk Soal ini"
                            }
                            if(item.jawaban != null && item.soal){
                              if(!item.isEssay){
                                item.choices[item.jawaban].isTrue = true
                                item.jawaban = item.choices[item.jawaban].value
                              }
                            }
                          }
                        }
                        newFields.push(item)
                    })
                    res({haveError,newFields,isFiles,isVoices})
                }else{
                    rej({haveError:true,newFields})
                }
            }
            catch(e){
                rej(e)
            }
        }.bind(this))
    }
    validateSoaldanJawaban(fields,type){
        // eslint-disable-next-line no-unused-vars
        return new Promise(function(res,rej){
            try{
                if(type != null){
                    var haveError = false;
                    var newFields = [];
                    fields.forEach((item)=>{
                      if(item.soal == ""){
                        item.error = true
                        haveError = true
                        item.errorMessage = "Soal Harus Di isi !"
                      }
                      if(item.jawaban == null){
                        haveError = true
                        item.error = true
                        item.errorMessage = "Pilih Jawaban untuk Soal ini"
                      }
                        newFields.push(item)
                    })
                    res({haveError,newFields})
                }else{
                    rej({haveError:true,newFields})
                }
            }
            catch(e){
                rej(e)
            }
        }.bind(this))
    }
    validateSoaldanJawabanImla(fields,type){
        // eslint-disable-next-line no-unused-vars
        return new Promise(function(res,rej){
            try{
                if(type != null){
                    var haveError = false;
                    var newFields = [];
                    fields.forEach((item)=>{
                      if(item.soal == ""){
                        item.error = true
                        haveError = true
                        item.errorMessage = "Soal Harus Di isi !"
                      }
                      if(item.soal_dua == ""){
                        item.error = true
                        haveError = true
                        item.errorMessage = "Soal dua Harus Di isi !"
                      }
                      if(item.soal_tiga == ""){
                        item.error = true
                        haveError = true
                        item.errorMessage = "Soal tiga Harus Di isi !"
                      }
                      if(item.jawaban == null){
                        haveError = true
                        item.error = true
                        item.errorMessage = "Pilih Jawaban untuk Soal ini"
                      }
                        newFields.push(item)
                    })
                    res({haveError,newFields})
                }else{
                    rej({haveError:true,newFields})
                }
            }
            catch(e){
                rej(e)
            }
        }.bind(this))
    }
    validateSoalPsikotes(fields,type){
        // eslint-disable-next-line no-unused-vars
        return new Promise(function(res,rej){
            try{
              console.log(fields);
                if(type != null){
                    var haveError = false;
                    var newFields = [];
                    fields.forEach((item)=>{
                      if(item.textPembahasan == ""){
                        haveError = true
                        item.error = true
                        item.errorMessage = "Tulis Pembahasan untuk Soal ini"
                      }
                      if(item.soal == ""){
                        item.error = true
                        haveError = true
                        item.errorMessage = "Soal Harus Di isi !"
                      }
                        newFields.push(item)
                    })
                    res({haveError,newFields})
                }else{
                    rej({haveError:true,newFields})
                }
            }
            catch(e){
                rej(e)
            }
        }.bind(this))
    }
    validateEdit(fields){
        // eslint-disable-next-line no-unused-vars
        return new Promise(function(res,rej){
            try{
              fields.forEach((item, i) => {
                item.choicesoals[i].isTrue =false;
              });

                var haveError = false;
                var newFields = []
                var isFiles = false;
                fields.forEach((item)=>{
                    if(item.soals == ""){
                        item.error = true
                        haveError = true
                        item.errorMessage = "Soal Harus Di isi !"
                    }
                    if(item.isFile == true){
                      isFiles = true;
                    }else{
                      if(item.jawaban == null){
                        haveError = true
                        item.error = true
                        item.errorMessage = "Pilih Jawaban untuk Soal ini"
                      }
                      console.log('============='+item.jawaban);
                      console.log('============='+item.choicesoals);
                      if(item.jawaban != null && item.soals){
                        if(!item.isEssay){
                          item.choicesoals[item.jawaban].isTrue = true
                          item.jawaban = item.choicesoals[item.jawaban].choice
                        }
                      }
                    }
                    newFields.push(item)
                })
                res({haveError,newFields,isFiles})
            }
            catch(e){
                rej(e)
            }
        }.bind(this))
    }
    UploadFile(file){
        return new Promise(function(resolve,reject){
            this.postFormdata('upload',file)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}

module.exports = Soal;
