/* eslint-disable no-unused-vars */
export const state = {
    areas: [],
    area : null
}

export const mutations = {
    SET_AREA(state, newValue) {
        state.area = newValue;
    },
    SET_AREAS(state, newValue) {
        state.areas = newValue;
    },
}

export const getters = {
    // Whether the user is currently logged in.
    area(state) {
        return state.area;
    },
    areas(state) {
        return state.areas;
    }
}
