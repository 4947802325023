/* eslint-disable no-unused-vars */

const Guru = require('./../../api/guru');
const Gurus = new Guru();
// LIST,CREATE,EDIT

export const state = {
    items: [],
    selected : null,
    states : "LIST",
    notif : null,
    loading : false,
}

export const mutations = {
    SET_ITEMS(state, newValue) {
        state.items = newValue;
    },
    SET_SELECTED(state, newValue) {
        state.selected = newValue;
    },
    SET_STATE(state, newValue) {
        state.states = newValue;
    },
    SET_NOTIF(state, newValue) {
        state.notif = newValue;
    },
    SET_LOADING(state, newValue) {
        state.loading = newValue;
    },
}

export const getters = {
    // Whether the user is currently logged in.
    items(state) {
        return state.items;
    },
    selected(state) {
        return state.selected;
    },
    states(state){
        return state.states
    },
    notif(state){
        return state.notif
    },
    loading(state){
        return state.loading
    }
}

export const actions = {
    getGuru(context) {
        const { commit, dispatch, getters } = context;
        return new Promise((resolve, reject) => {
            Gurus.data({offset:0,limit:1000}).then((res) => {
                commit('SET_ITEMS', res);
            }).catch((err)=>{
                console.log(err);
            })
        })
    },
    createGuru(context,data) {
        const { commit, dispatch, getters } = context;
        commit('SET_LOADING', true);
        const formData = new FormData();
        console.log(data);
        formData.append('nama',data.nama);
        formData.append('nip',data.nip);
        formData.append('email',data.email);
        formData.append('phone',data.phone);
        formData.append('password',data.password);
        return new Promise((resolve, reject) => {
            Gurus.create(formData).then((res) => {
                commit('SET_LOADING', false);
                commit('SET_STATE', "LIST");
                commit('SET_NOTIF', res);
                dispatch('getGuru');
            }).catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_STATE', "CREATE");
                commit('SET_NOTIF', err);
                dispatch('getGuru');
            })
        })
    },
    hapusGuru(context,id) {
        const { commit, dispatch, getters } = context;
        commit('SET_LOADING', true);
        return new Promise((resolve, reject) => {
            Gurus.hapus(id).then((res) => {
                commit('SET_LOADING', false);
                commit('SET_STATE', "LIST");
                commit('SET_NOTIF', res);
                dispatch('getGuru');
            }).catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_STATE', "LIST");
                commit('SET_NOTIF', err);
            })
        })
    },
    
}


