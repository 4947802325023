
const Client = require('./client');
const qs = require('qs')
class Area extends Client{
    constructor() {
        super();
        this._endpoint = 'area';
    }
    create(data){
        return new Promise(function(resolve,reject){
            this.post(this._endpoint,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    data(){
        return new Promise(function(resolve,reject){
            this.get(this._endpoint)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    edit(data){
        return new Promise(function(resolve,reject){
            this.put(this._endpoint+"/"+data.id,qs.stringify({
                area: data.name
              }))
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapus(data){
        return new Promise(function(resolve,reject){
            this.delete(this._endpoint+"/"+data.id)
            .then((result)=>{
                if (result.success) {
                    resolve(result)
                } else {
                    reject(result)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    report(){
        return new Promise(function(resolve,reject){
            this.get(this._endpoint+"/report")
            .then((result)=>{
                if (result.success) {
                    resolve(result.data)
                } else {
                    console.log(result)
                    console.log("gonlb")
                    reject(result)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}

module.exports = Area;