/* eslint-disable no-unused-vars */

const Koreksi = require('./../../api/koreksi');
const Koreksis = new Koreksi();
// LIST,DIALOG,MATPEL,SOAL

export const state = {
    items: [],
    selected : null,
    koreksi : null,
    states : "LIST",
    notif : null,
    loading : false,
    matpels : null,
    soals : null,
    points : null,
}

export const mutations = {
    SET_ITEMS(state, newValue) {
        state.items = newValue;
    },
    SET_SELECTED(state, newValue) {
        state.selected = newValue;
    },
    SET_KOREKSI(state, newValue) {
      state.koreksi = newValue;
    },
    SET_MATPELS(state, newValue) {
        state.matpels = newValue;
    },
    SET_SOALS(state, newValue) {
        state.soals = newValue;
    },
    SET_POINTS(state, newValue) {
        state.points = newValue;
    },
    SET_STATE(state, newValue) {
        state.states = newValue;
    },
    SET_NOTIF(state, newValue) {
        state.notif = newValue;
    },
    SET_LOADING(state, newValue) {
        state.loading = newValue;
    },
}

export const getters = {
    // Whether the user is currently logged in.
    items(state) {
        return state.items
    },
    selected(state) {
        return state.selected
    },
    koreksi(state) {
        return state.koreksi
    },
    matpels(state) {
        return state.matpels
    },
    soals(state) {
        return state.soals
    },
    points(state) {
        return state.points
    },
    states(state){
        return state.states
    },
    notif(state){
        return state.notif
    },
    loading(state){
        return state.loading
    }
}

export const actions = {
    getItems(context) {
        const { commit, dispatch, getters } = context;
        commit('SET_LOADING', true);
        commit('SET_NOTIF', null);
        return new Promise((resolve, reject) => {
            Koreksis.list().then((res) => {
                var type = context.rootGetters["users/type"];
                var user = context.rootGetters["users/user"];
                if(type == "GURU"){
                    if(res){
                        res = res.filter((v)=>{
                                return v.id_guru == null || v.id_guru == user.data_guru.id
                        })
                    }
                }
                commit('SET_ITEMS', res);
                commit('SET_LOADING', false);
            }).catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_NOTIF', err);
            })
        })
    },
    getSoal(context) {
      const { commit, dispatch, getters } = context;
      commit('SET_LOADING', true);
      commit('SET_NOTIF', null);
      var koreksi = getters["koreksi"];
      switch (koreksi.urutan) {
        case 1:
            dispatch("getSoalPsikotes");
          break;
        case 2:
            dispatch("getSoalBacaQuran");
          break;
        case 3:
            dispatch("getSoalHukumTajwids");
          break;
        case 4:
            dispatch("getSoalHafalanJuz");
          break;
        case 5:
            dispatch("getSoalAmmaliyah");
          break;
        case 6:
            dispatch("getSoalQoliyah");
          break;
        case 7:
            dispatch("getSoalImla");
          break;
        case 8:
            dispatch("getSoalBerhitungAngka");
          break;
        case 9:
            dispatch("getSoalBerhitungSoal");
          break;
        case 10:
            dispatch("getSoalBahasaIndonesia");
          break;
        default:
          console.log('default');
          commit('SET_LOADING', false);
      }
    },
    getSoalPsikotes(context) {
      const { commit, dispatch, getters } = context;
      var koreksi = getters["koreksi"];
      return new Promise((resolve, reject) => {
          if(koreksi != null){
              Koreksis.getSoalPsikotes(koreksi.id_tryoutDetail).then((res) => {
              if(res){
                  console.log(res);
                  commit('SET_SOALS', res);
                  // commit('SET_STATE', "SOAL");
                  commit('SET_LOADING', false);
              }
              }).catch((err)=>{
                  commit('SET_LOADING', false);
                  commit('SET_NOTIF', err);
              })
          }
      })
    },
    getSoalBacaQuran(context) {
      const { commit, dispatch, getters } = context;
      var koreksi = getters["koreksi"];
      return new Promise((resolve, reject) => {
          if(koreksi != null){
              Koreksis.getSoalBacaQuran(koreksi.id_tryoutDetail).then((res) => {
              if(res){
                  console.log(res);
                  commit('SET_SOALS', res);
                  // commit('SET_STATE', "SOAL");
                  commit('SET_LOADING', false);
              }
              }).catch((err)=>{
                  commit('SET_LOADING', false);
                  commit('SET_NOTIF', err);
              })
          }
      })
    },
    getSoalHukumTajwids(context) {
      const { commit, dispatch, getters } = context;
      var koreksi = getters["koreksi"];
      return new Promise((resolve, reject) => {
          if(koreksi != null){
              Koreksis.getSoalHukumTajwids(koreksi.id_tryoutDetail).then((res) => {
              if(res){
                  console.log(res);
                  commit('SET_SOALS', res);
                  // commit('SET_STATE', "SOAL");
                  commit('SET_LOADING', false);
              }
              }).catch((err)=>{
                  commit('SET_LOADING', false);
                  commit('SET_NOTIF', err);
              })
          }
      })
    },
    getSoalHafalanJuz(context) {
      const { commit, dispatch, getters } = context;
      var koreksi = getters["koreksi"];
      return new Promise((resolve, reject) => {
          if(koreksi != null){
              Koreksis.getSoalHafalanJuz(koreksi.id_tryoutDetail).then((res) => {
              if(res){
                  console.log(res);
                  commit('SET_SOALS', res);
                  // commit('SET_STATE', "SOAL");
                  commit('SET_LOADING', false);
              }
              }).catch((err)=>{
                  commit('SET_LOADING', false);
                  commit('SET_NOTIF', err);
              })
          }
      })
    },
    getSoalAmmaliyah(context) {
      const { commit, dispatch, getters } = context;
      var koreksi = getters["koreksi"];
      return new Promise((resolve, reject) => {
          if(koreksi != null){
              Koreksis.getSoalAmmaliyah(koreksi.id_tryoutDetail).then((res) => {
              if(res){
                  console.log(res);
                  commit('SET_SOALS', res);
                  // commit('SET_STATE', "SOAL");
                  commit('SET_LOADING', false);
              }
              }).catch((err)=>{
                  commit('SET_LOADING', false);
                  commit('SET_NOTIF', err);
              })
          }
      })
    },
    getSoalQoliyah(context) {
      const { commit, dispatch, getters } = context;
      var koreksi = getters["koreksi"];
      return new Promise((resolve, reject) => {
          if(koreksi != null){
              Koreksis.getSoalQoliyah(koreksi.id_tryoutDetail).then((res) => {
              if(res){
                  console.log(res);
                  commit('SET_SOALS', res);
                  // commit('SET_STATE', "SOAL");
                  commit('SET_LOADING', false);
              }
              }).catch((err)=>{
                  commit('SET_LOADING', false);
                  commit('SET_NOTIF', err);
              })
          }
      })
    },
    getSoalImla(context) {
      const { commit, dispatch, getters } = context;
      var koreksi = getters["koreksi"];
      return new Promise((resolve, reject) => {
          if(koreksi != null){
              Koreksis.getSoalImla(koreksi.id_tryoutDetail).then((res) => {
              if(res){
                  console.log(res);
                  commit('SET_SOALS', res);
                  // commit('SET_STATE', "SOAL");
                  commit('SET_LOADING', false);
              }
              }).catch((err)=>{
                  commit('SET_LOADING', false);
                  commit('SET_NOTIF', err);
              })
          }
      })
    },
    getSoalBerhitungAngka(context) {
      const { commit, dispatch, getters } = context;
      var koreksi = getters["koreksi"];
      return new Promise((resolve, reject) => {
          if(koreksi != null){
              Koreksis.getSoalBerhitungAngka(koreksi.id_tryoutDetail).then((res) => {
              if(res){
                  console.log(res);
                  commit('SET_SOALS', res);
                  // commit('SET_STATE', "SOAL");
                  commit('SET_LOADING', false);
              }
              }).catch((err)=>{
                  commit('SET_LOADING', false);
                  commit('SET_NOTIF', err);
              })
          }
      })
    },
    getSoalBerhitungSoal(context) {
      const { commit, dispatch, getters } = context;
      var koreksi = getters["koreksi"];
      return new Promise((resolve, reject) => {
          if(koreksi != null){
              Koreksis.getSoalBerhitungSoal(koreksi.id_tryoutDetail).then((res) => {
              if(res){
                  console.log(res);
                  commit('SET_SOALS', res);
                  // commit('SET_STATE', "SOAL");
                  commit('SET_LOADING', false);
              }
              }).catch((err)=>{
                  commit('SET_LOADING', false);
                  commit('SET_NOTIF', err);
              })
          }
      })
    },
    getSoalBahasaIndonesia(context) {
      const { commit, dispatch, getters } = context;
      var koreksi = getters["koreksi"];
      return new Promise((resolve, reject) => {
          if(koreksi != null){
              Koreksis.getSoalBahasaIndonesia(koreksi.id_tryoutDetail).then((res) => {
              if(res){
                  console.log(res);
                  commit('SET_SOALS', res);
                  // commit('SET_STATE', "SOAL");
                  commit('SET_LOADING', false);
              }
              }).catch((err)=>{
                  commit('SET_LOADING', false);
                  commit('SET_NOTIF', err);
              })
          }
      })
    },
    startKoreksi(context) {
        const { commit, dispatch, getters } = context;
        commit('SET_LOADING', true);
        commit('SET_NOTIF', null);
        var tryout = getters["selected"];
        console.log(tryout);
        var user = context.rootGetters["users/user"];
        return new Promise((resolve, reject) => {
            Koreksis.mulaiKoreksi(tryout.id,user.data_guru.id).then((res) => {
                if(res){
                    dispatch("getMatpel");
                    commit('SET_STATE', "MATPEL");
                    commit('SET_LOADING', false);
                }
            }).catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_NOTIF', err);
            })
        })
    },
    LanjutKoreksi(context) {
        const { commit, dispatch, getters } = context;
        commit('SET_LOADING', true);
        commit('SET_NOTIF', null);
        dispatch("getMatpel");
        commit('SET_STATE', "MATPEL");
        commit('SET_LOADING', false);
    },
    bukaSoal(context) {
      const { commit, dispatch, getters } = context;
      commit('SET_LOADING', true);
      commit('SET_NOTIF', null);
      dispatch("getSoal");
      commit('SET_STATE', "SOAL");
      commit('SET_LOADING', false);
    },
    kumpulkanNilai(context) {
      const { commit, dispatch, getters } = context;
      var point = getters["points"];
      var koreksi = getters["koreksi"];
      console.log(koreksi.id_tryoutDetail);
      commit('SET_LOADING', true);
      commit('SET_NOTIF', null);
      Koreksis.simpanNilaiKoreksi(koreksi.id_tryoutDetail, point)
      .then((res)=>{
        if(res){
            commit('SET_NOTIF', 'berhasil di update!');
            dispatch("getMatpel");
            commit('SET_STATE', "LIST");
            commit('SET_LOADING', false);
        }
      })
      .catch((err)=>{
        commit('SET_LOADING', false);
        commit('SET_NOTIF', err);
      })
    },
    getMatpel(context){
        const { commit, dispatch, getters } = context;
        commit('SET_LOADING', true);
        commit('SET_NOTIF', null);
        var tryout = getters["selected"];
        return new Promise((resolve, reject) => {
            if(tryout != null){
                Koreksis.dataMatpelPondok(tryout.id).then((res) => {
                if(res){
                    commit('SET_MATPELS', res);
                    commit('SET_LOADING', false);
                }
                }).catch((err)=>{
                    commit('SET_LOADING', false);
                    commit('SET_NOTIF', err);
                })
            }
        })
    }
}
