/* eslint-disable no-unused-vars */
const Soal = require('./../../api/soal');
export const state = {
    error : [],
    items: [],
    loading : [],
    success : [],
    id : 0,
}

export const mutations = {
    SET_ERROR(state, error) {
        state.error.push(error)
    },
    SET_ITEMS(state, newValue) {
        state.items = newValue;
    },
    SET_LOADING(state, loading) {
        state.loading.push(loading)
    },
    SET_SUCCESS(state, success) {
        state.success.push(success)
    },
    DELETE_ERROR(state, item) {
        var index = state.error.map((e)=>e.id).indexOf(item.id);
        state.error.splice(index,1)
    },
    DELETE_LOADING(state, item) {
        var index = state.loading.map((e)=>e.id).indexOf(item.id);
        state.loading.splice(index,1)
    },
    DELETE_SUCCESS(state, item) {
        var index = state.success.map((e)=>e.id).indexOf(item.id);
        state.success.splice(index,1)
    },
    SET_ID(state, item) {
      state.id = item;
    }
}


export const getters = {
    // Whether the user is currently logged in.
    error(state) {
        return state.error
    },
    items(state) {
        return state.items;
    },
    loading(state) {
        return state.loading
    },
    success(state) {
        return state.success
    },
    getId(state) {
      return state.id
    }
}

export const actions = {
    // eslint-disable-next-line no-unused-vars
    getSoals(context,filter) {
        const { commit, dispatch, getters } = context;
        var _Soal = new Soal();
        return new Promise((resolve, reject) => {
            _Soal.data(filter).then((res) => {
                commit('SET_ITEMS', res);
            }).catch((err)=>{
                console.log(err);
            })
        })
    },
    save(context,data){
    // console.log(this)
        const { commit, dispatch, getters } = context;
        const { soal, matpel, type, user } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{
            // {
            //     "soals": "string",
            //     "id_type": 1,
            //     "id_matpel": 1,
            //     "jawaban": "string",
            //     "isTrue": 0,
            //     "choice": [
            //       {
            //         "value": "string",
            //         "isTrue": 0
            //       }
            //     ]
            //   }
            var param = {
                "soals" : item.soal,
                "id_type":type,
                "id_matpel":matpel,
                "jawaban":item.jawaban,
                "isEssay":item.isEssay,
                "id_user":user,
                "choice":item.choices.map((i)=>{return {"value":i.value,"isTrue":i.isTrue}})
            }
            const formData = new FormData();
            formData.append('soals',item.soal);
            formData.append('id_type',type);
            formData.append('id_matpel',matpel);
            formData.append('jawaban',item.jawaban);
            formData.append('isEssay',item.isEssay ? 1 : 0);
            formData.append('imgPembahasan',item.imgPembahasan ? 1 : 0);
            formData.append('textPembahasan',item.textPembahasan);
            formData.append('id_user',user);
            item.choices.forEach((item,index)=>{
                formData.append(`choice[${index}][value]`,item.value);
                formData.append(`choice[${index}][isTrue]`,item.isTrue ? 1 : 0);
            });
            formData.append('picture',item.pembahasan);


            // data.append('soals', 'test');
            // data.append('id_type', '224');
            // data.append('id_matpel', '2');
            // data.append('jawaban', 'asd');
            // data.append('picture', fs.createReadStream('/home/donbay/Pictures/Screenshot from 2020-10-09 21-51-51.png'));
            // data.append('choice[0][value]', 'test');
            // data.append('choice[0][isTrue]', '0');
            // data.append('isEssay', '0');
            // data.append('choice[1][value]', 'asd');
            // data.append('choice[1][isTrue]', '1');

            commit('SET_LOADING',item);
            _Soal.create(formData)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal ke ${item.id+1} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal ke ${item.id+1} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    saveSoalPaket(context,data){
    console.log(data)
        const { commit, dispatch, getters } = context;
        const { soal, types, user } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{
            // {
            //     "soals": "string",
            //     "id_type": 1,
            //     "id_matpel": 1,
            //     "jawaban": "string",
            //     "isTrue": 0,
            //     "choice": [
            //       {
            //         "value": "string",
            //         "isTrue": 0
            //       }
            //     ]
            //   }
            var param = {
                "id_type_new":types,
                "id_user":user,
            }
            const formData = new FormData();
            formData.append('id',item.idSoal);
            formData.append('id_type_new',types);
            formData.append('id_user',user);


            // data.append('soals', 'test');
            // data.append('id_type', '224');
            // data.append('id_matpel', '2');
            // data.append('jawaban', 'asd');
            // data.append('picture', fs.createReadStream('/home/donbay/Pictures/Screenshot from 2020-10-09 21-51-51.png'));
            // data.append('choice[0][value]', 'test');
            // data.append('choice[0][isTrue]', '0');
            // data.append('isEssay', '0');
            // data.append('choice[1][value]', 'asd');
            // data.append('choice[1][isTrue]', '1');

            commit('SET_LOADING',item);
            _Soal.updateSoal(formData,item.idSoal)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal id ${item.idSoal} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal id ${item.idSoal} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    saveBacaQuran(context,data){
    console.log('thos')
        const { commit, dispatch, getters } = context;
        const { soal, matpel, type } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{
            // {
            //     "soals": "string",
            //     "id_type": 1,
            //     "id_matpel": 1,
            //     "jawaban": "string",
            //     "isTrue": 0,
            //     "choice": [
            //       {
            //         "value": "string",
            //         "isTrue": 0
            //       }
            //     ]
            //   }
            var param = {
                "id_paketPondok":type,
                "soal" : item.soal,
                "kunciJawaban":item.jawaban,
            }
            const formData = new FormData();
            formData.append('id_paketPondok',type);
            formData.append('soal',item.soal);
            formData.append('kunciJawaban',item.jawaban);


            // data.append('soals', 'test');
            // data.append('id_type', '224');
            // data.append('id_matpel', '2');
            // data.append('jawaban', 'asd');
            // data.append('picture', fs.createReadStream('/home/donbay/Pictures/Screenshot from 2020-10-09 21-51-51.png'));
            // data.append('choice[0][value]', 'test');
            // data.append('choice[0][isTrue]', '0');
            // data.append('isEssay', '0');
            // data.append('choice[1][value]', 'asd');
            // data.append('choice[1][isTrue]', '1');

            commit('SET_LOADING',item);
            _Soal.createBacaQuran(formData)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal ke ${item.id+1} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal ke ${item.id+1} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    savePsikotes(context,data){
      // console.log(this)
        const { commit, dispatch, getters } = context;
        const { soal, matpel, type } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{

            var param = {
                "id_paketPondok":type,
                "soal" : item.soal,
                "kunciJawaban":item.jawaban,
            }
            const formData = new FormData();
            formData.append('id_paketPondok',type);
            formData.append('soal',item.soal);
            formData.append('kunciJawaban',item.jawaban);

            commit('SET_LOADING',item);
            console.log(formData);
            _Soal.createPsikotes(formData)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal ke ${item.id+1} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal ke ${item.id+1} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    saveAmaliyah(context,data){
    // console.log(this)
        const { commit, dispatch, getters } = context;
        const { soal, matpel, type } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{

            var param = {
                "id_paketPondok":type,
                "soal" : item.soal,
                "kunciJawaban":item.jawaban,
            }
            const formData = new FormData();
            formData.append('id_paketPondok',type);
            formData.append('soal',item.soal);
            formData.append('kunciJawaban',item.jawaban);

            commit('SET_LOADING',item);
            _Soal.createAmaliyah(formData)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal ke ${item.id+1} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal ke ${item.id+1} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    saveQoliyah(context,data){
    // console.log(this)
        const { commit, dispatch, getters } = context;
        const { soal, matpel, type } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{

            var param = {
                "id_paketPondok":type,
                "soal" : item.soal,
                "kunciJawaban":item.jawaban,
            }
            const formData = new FormData();
            formData.append('id_paketPondok',type);
            formData.append('soal',item.soal);
            formData.append('kunciJawaban',item.jawaban);

            commit('SET_LOADING',item);
            _Soal.createQoliyah(formData)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal ke ${item.id+1} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal ke ${item.id+1} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    saveHafalanJuz(context,data){
    // console.log(this)
        const { commit, dispatch, getters } = context;
        const { soal, matpel, type } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{

            var param = {
                "id_paketPondok":type,
                "soal" : item.soal,
                "kunciJawaban":item.jawaban,
            }
            const formData = new FormData();
            formData.append('id_paketPondok',type);
            formData.append('soal',item.soal);
            formData.append('kunciJawaban',item.jawaban);

            commit('SET_LOADING',item);
            _Soal.createHafalanJuz(formData)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal ke ${item.id+1} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal ke ${item.id+1} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    saveBerhitungAngka(context,data){
    // console.log(this)
        const { commit, dispatch, getters } = context;
        const { soal, matpel, type } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{

            var param = {
                "id_paketPondok":type,
                "soal" : item.soal,
                "kunciJawaban":item.jawaban,
                "waktu":3600000,
            }
            const formData = new FormData();
            formData.append('id_paketPondok',type);
            formData.append('soal',item.soal);
            formData.append('kunciJawaban',item.jawaban);
            formData.append('waktu',3600000);

            commit('SET_LOADING',item);
            _Soal.createBerhitungAngka(formData)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal ke ${item.id+1} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal ke ${item.id+1} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    saveBerhitungSoal(context,data){
    // console.log(this)
        const { commit, dispatch, getters } = context;
        const { soal, matpel, type } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{

            var param = {
                "id_paketPondok":type,
                "soal" : item.soal,
                "kunciJawaban":item.jawaban,
                "waktu":3600000,
            }
            const formData = new FormData();
            formData.append('id_paketPondok',type);
            formData.append('soal',item.soal);
            formData.append('kunciJawaban',item.jawaban);
            formData.append('waktu',3600000);

            commit('SET_LOADING',item);
            _Soal.createBerhitungSoal(formData)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal ke ${item.id+1} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal ke ${item.id+1} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    saveBahasaIndonesia(context,data){
    // console.log(this)
        const { commit, dispatch, getters } = context;
        const { soal, matpel, type } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{

            var param = {
                "id_paketPondok":type,
                "soal" : item.soal,
                "kunciJawaban":item.jawaban,
                "waktu":3600000,
            }
            const formData = new FormData();
            formData.append('id_paketPondok',type);
            formData.append('soal',item.soal);
            formData.append('kunciJawaban',item.jawaban);
            formData.append('waktu',3600000);

            commit('SET_LOADING',item);
            _Soal.createBahasaIndonesia(formData)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal ke ${item.id+1} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal ke ${item.id+1} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    saveHukumTajwids(context,data){
    // console.log(this)
        const { commit, dispatch, getters } = context;
        const { soal, matpel, type } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{

            var param = {
                "id_paketPondok":type,
                "soal" : item.soal,
                "kunciJawaban":item.jawaban,
            }
            const formData = new FormData();
            formData.append('id_paketPondok',type);
            formData.append('soal',item.soal);
            formData.append('kunciJawaban',item.jawaban);

            commit('SET_LOADING',item);
            console.log(formData);
            _Soal.createHukumTajwids(formData)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal ke ${item.id+1} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal ke ${item.id+1} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    saveImla(context,data){
    // console.log(this)
        const { commit, dispatch, getters } = context;
        const { soal, type } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{

            var param = {
                "id_paketPondok":type,
                "soal" : item.soal,
                "soal_dua" : item.soal_dua,
                "soal_tiga" : item.soal_tiga,
                "kunciJawaban":item.jawaban,
            }
            const formData = new FormData();
            formData.append('id_paketPondok',type);
            formData.append('soal',item.soal);
            formData.append('soal_dua',item.soal_dua);
            formData.append('soal_tiga',item.soal_tiga);
            formData.append('kunciJawaban',item.jawaban);

            commit('SET_LOADING',item);
            _Soal.createImla(formData)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal ke ${item.id+1} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal ke ${item.id+1} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    saveEdit(context,data){
    // console.log(this)
        const { commit, dispatch, getters } = context;
        const { soal } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{
            // {
            //     "soals": "string",
            //     "id_type": 1,
            //     "id_matpel": 1,
            //     "jawaban": "string",
            //     "isTrue": 0,
            //     "choice": [
            //       {
            //         "value": "string",
            //         "isTrue": 0
            //       }
            //     ]
            //   }
            var param = {
                "soals" : item.soals,
                "id_type":item.id_type,
                "id_matpel":item.id_matpel,
                "jawaban":item.jawaban,
                "isEssay":item.isEssay,
                "choice":item.choicesoals.map((i)=>{return {"value":i.choice,"isTrue":i.isTrue}})
            }
            const formData = new FormData();
            formData.append('id',item.id);
            formData.append('soals',item.soals);
            formData.append('id_type',item.id_type);
            formData.append('id_matpel',item.id_matpel);
            formData.append('jawaban',item.jawaban);
            formData.append('isEssay',item.isEssay ? 1 : 0);
            formData.append('imgPembahasan',item.imgPembahasan ? 1 : 0);
            formData.append('textPembahasan',item.pembahasan);
            item.choicesoals.forEach((item,index)=>{
                formData.append(`choice[${index}][value]`,item.choice);
                formData.append(`choice[${index}][isTrue]`,item.isTrue ? 1 : 0);
            });
            formData.append('picture',item.fotoPembahasan);


            // data.append('soals', 'test');
            // data.append('id_type', '224');
            // data.append('id_matpel', '2');
            // data.append('jawaban', 'asd');
            // data.append('picture', fs.createReadStream('/home/donbay/Pictures/Screenshot from 2020-10-09 21-51-51.png'));
            // data.append('choice[0][value]', 'test');
            // data.append('choice[0][isTrue]', '0');
            // data.append('isEssay', '0');
            // data.append('choice[1][value]', 'asd');
            // data.append('choice[1][isTrue]', '1');

            commit('SET_LOADING',item);
            _Soal.updateSoal(formData,item.id)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal berhasil di update`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal gagal diupdate`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    saveVoice(context,data){
    console.log(data);
        const { commit, dispatch, getters } = context;
        const { soal, matpel, type } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{
            // {
            //     "soals": "string",
            //     "id_type": 1,
            //     "id_matpel": 1,
            //     "jawaban": "string",
            //     "isTrue": 0,
            //     "choice": [
            //       {
            //         "value": "string",
            //         "isTrue": 0
            //       }
            //     ]
            //   }
            var param = {
                "soalFile" : item.soalFile,
                "id_type":type,
                "id_matpel":matpel,
                "pembahasan" : item.pembahasan
            }
            const formData = new FormData();
            formData.append('soalFile',item.soalFile);
            formData.append('id_type',type);
            formData.append('id_matpel',matpel);
            formData.append('jawaban_benar',item.jawaban);


            // data.append('soals', 'test');
            // data.append('id_type', '224');
            // data.append('id_matpel', '2');
            // data.append('jawaban', 'asd');
            // data.append('picture', fs.createReadStream('/home/donbay/Pictures/Screenshot from 2020-10-09 21-51-51.png'));
            // data.append('choice[0][value]', 'test');
            // data.append('choice[0][isTrue]', '0');
            // data.append('isEssay', '0');
            // data.append('choice[1][value]', 'asd');
            // data.append('choice[1][isTrue]', '1');

            commit('SET_LOADING',item);
            _Soal.createVoice(formData)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal ke ${item.id+1} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal ke ${item.id+1} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    },
    saveFile(context,data){
    console.log(data);
        const { commit, dispatch, getters } = context;
        const { soal, matpel, type } = data;
        var _Soal = new Soal();
        soal.forEach((item)=>{
            // {
            //     "soals": "string",
            //     "id_type": 1,
            //     "id_matpel": 1,
            //     "jawaban": "string",
            //     "isTrue": 0,
            //     "choice": [
            //       {
            //         "value": "string",
            //         "isTrue": 0
            //       }
            //     ]
            //   }
            var param = {
                "soals" : item.soal,
                "id_type":type,
                "id_matpel":matpel
            }
            const formData = new FormData();
            formData.append('soals',item.soal);
            formData.append('id_type',type);
            formData.append('id_matpel',matpel);


            // data.append('soals', 'test');
            // data.append('id_type', '224');
            // data.append('id_matpel', '2');
            // data.append('jawaban', 'asd');
            // data.append('picture', fs.createReadStream('/home/donbay/Pictures/Screenshot from 2020-10-09 21-51-51.png'));
            // data.append('choice[0][value]', 'test');
            // data.append('choice[0][isTrue]', '0');
            // data.append('isEssay', '0');
            // data.append('choice[1][value]', 'asd');
            // data.append('choice[1][isTrue]', '1');

            commit('SET_LOADING',item);
            _Soal.createVoice(formData)
            .then((res)=>{
                commit('DELETE_LOADING',item);
                if(res.success){
                    commit('SET_SUCCESS',item);
                    this._vm.$toasted.success(`Soal ke ${item.id+1} Success`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                }else{
                    this._vm.$toasted.error(`Soal ke ${item.id+1} Failed`, {
                        theme: "toasted-primary",
                        position: "bottom-right",
                        duration : null
                   });
                    commit('SET_ERROR',item);
                }
            })
            .catch((e)=>{
                commit('SET_ERROR',item);
            })
        })
    }
}
