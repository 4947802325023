/* eslint-disable no-unused-vars */

const Murid = require('./../../api/murid');
const Murids = new Murid();
// LIST,CREATE,EDIT

export const state = {
    items: [],
    selected : null,
    states : "LIST",
    notif : null,
    loading : false,
}

export const mutations = {
    SET_ITEMS(state, newValue) {
        state.items = newValue;
    },
    SET_SELECTED(state, newValue) {
        state.selected = newValue;
    },
    SET_STATE(state, newValue) {
        state.states = newValue;
    },
    SET_NOTIF(state, newValue) {
        state.notif = newValue;
    },
    SET_LOADING(state, newValue) {
        state.loading = newValue;
    },
}

export const getters = {
    // Whether the user is currently logged in.
    items(state) {
        return state.items;
    },
    selected(state) {
        return state.selected;
    },
    states(state){
        return state.states
    },
    notif(state){
        return state.notif
    },
    loading(state){
        return state.loading
    }
}

export const actions = {
    getMurids(context,filter) {
        const { commit, dispatch, getters } = context;
        return new Promise((resolve, reject) => {
            Murids.data(filter).then((res) => {
                commit('SET_ITEMS', res);
            }).catch((err)=>{
                console.log(err);
            })
        })
    },
    
}


